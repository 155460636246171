import React from 'react'
import classnames from 'classnames'
import mixpanel from 'mixpanel-browser'

import CardLayout from './cardLayout'
import CardTitle from './cardTitle'
import axios from 'axios'

import styles from './mood.module.css'

import BigSmileImage from '../images/big-smile.png'
import SmileImage from '../images/smile.png'
import NeutralImage from '../images/neutral.png'
import MehImage from '../images/meh.png'
import HorribleImage from '../images/horrible.png'

class MoodCard extends React.Component {
  constructor() {
    super()
    this.state = {
      currentMood: null,
      data: {},
    }
  }

  componentDidMount() {
    axios
      .get('https://dartmouthpulse.herokuapp.com/api/mood/dartmouth.edu')
      .then(res => {
        this.setState({ data: res.data.scores })
      })
  }
  // UNSAFE_componentWillMount() {
  //   // fetch mood data
  //   request(
  //     {
  //       json: true,
  //       url:
  //         'https://dartmouthpulse.herokuapp.com/api/mood/dartmouth.edu',
  //     },
  //     (er, res) => {
  //       if (!er) {
  //         this.setState({ data: res.body.scores })
  //       }
  //     }
  //   )
  // }

  sendMood = (mood, fn) => {
    axios
      .post(
        `https://dartmouthpulse.herokuapp.com/api/mood/dartmouth.edu/${mood}`
      )
      .then(res => {
        return fn(res.stats)
      })
      .catch(err => {
        return fn(err.response.status)
      })
  }

  onSelectMood = mood => () =>
    !this.state.currentMood &&
    this.sendMood(mood, statusCode => {
      mixpanel.track('Mood selected', { mood })
      if (statusCode === 429) {
        return this.setState({
          error: 'Only one vote counted per day',
          currentMood:
            this.state.currentMood ||
            (this.state.currentMood === mood ? null : mood),
        })
      }
      this.setState({
        currentMood:
          this.state.currentMood ||
          (this.state.currentMood === mood ? null : mood),
        data: this.state.currentMood
          ? this.state.data
          : {
              ...this.state.data,
              [mood]: this.state.data[mood] + 1,
            },
      })
    })

  renderSelectOptions() {
    const { currentMood, data } = this.state
    return (
      <div className={styles.moodsContainer}>
        <div className={styles.moodBtn} onClick={this.onSelectMood('terrible')}>
          <img
            className={classnames(styles.moodIcon, {
              [styles.moodIconInactive]:
                currentMood && currentMood !== 'terrible',
            })}
            src={HorribleImage}
            alt="terrible"
          />
          {currentMood && (
            <div className={styles.moodCount}>{data.terrible}</div>
          )}
          {!currentMood && <div className={styles.moodCount} />}
        </div>
        <div className={styles.moodBtn} onClick={this.onSelectMood('bad')}>
          <img
            className={classnames(styles.moodIcon, {
              [styles.moodIconInactive]: currentMood && currentMood !== 'bad',
            })}
            src={MehImage}
            alt="bad"
          />
          {currentMood && <div className={styles.moodCount}>{data.bad}</div>}
        </div>
        <div className={styles.moodBtn} onClick={this.onSelectMood('mediocre')}>
          <img
            className={classnames(styles.moodIcon, {
              [styles.moodIconInactive]:
                currentMood && currentMood !== 'mediocre',
            })}
            src={NeutralImage}
            alt="mediocre"
          />
          {currentMood && (
            <div className={styles.moodCount}>{data.mediocre}</div>
          )}
        </div>
        <div className={styles.moodBtn} onClick={this.onSelectMood('good')}>
          <img
            className={classnames(styles.moodIcon, {
              [styles.moodIconInactive]: currentMood && currentMood !== 'good',
            })}
            src={SmileImage}
            alt="good"
          />
          {currentMood && <div className={styles.moodCount}>{data.good}</div>}
        </div>
        <div className={styles.moodBtn} onClick={this.onSelectMood('great')}>
          <img
            className={classnames(styles.moodIcon, {
              [styles.moodIconInactive]: currentMood && currentMood !== 'great',
            })}
            src={BigSmileImage}
            alt="great"
          />
          {currentMood && <div className={styles.moodCount}>{data.great}</div>}
        </div>
      </div>
    )
  }

  render() {
    return (
      <CardLayout>
        <CardTitle cardTitle="How's It Going?" />
        {this.renderSelectOptions()}
        <div className={styles.errorContainer}>{this.state.error}</div>
      </CardLayout>
    )
  }
}

export default MoodCard
