import React from 'react'
import PropTypes from 'prop-types'

import CardLayout from './cardLayout'

import styles from './download.module.css'

import RightArrowImage from '../images/right-arrow.svg'

class DownloadCard extends React.Component {
  static propTypes = {
    count: PropTypes.number.isRequired,
  }
  render() {
    return (
      <CardLayout greenCard>
        <a
          className={styles.entireLink}
          href="https://bbuw.app.link/aQDnmdADAQ"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={styles.description}>
            Join <span className={styles.greenText}>{this.props.count}</span>{' '}
            Dartmouth Students on College Pulse
          </div>
          <div className={styles.footer}>
            <div className={styles.downloadLink}>
              Download the App
              <img
                className={styles.arrowIcon}
                src={RightArrowImage}
                alt="arrow"
              />
            </div>
          </div>
        </a>
      </CardLayout>
    )
  }
}

export default DownloadCard
