import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './card.module.css'

const CardTitle = ({ cardTitle, greenTitle, subtitle }) => (
  <h3
    className={classnames(styles.cardTitle, {
      [styles.greenTitle]: greenTitle,
    })}
  >
    {cardTitle}
    {subtitle && <span className={styles.cardSubtitle}>{subtitle}</span>}
  </h3>
)

CardTitle.propTypes = {
  cardTitle: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  greenTitle: PropTypes.bool,
}

CardTitle.defaultProps = {
  greenTitle: false,
  subtitle: '',
}

export default CardTitle
