import React from 'react'
import PropTypes from 'prop-types'
import mixpanel from 'mixpanel-browser'
// import Slideshow from 'react-slidez'
import styles from './byTheNumbers.module.css'
import './dots.css'

import CardLayout from './cardLayout'
import CardTitle from './cardTitle'
import ChevronLeft from '../images/chevron-left.svg'
import ChevronRight from '../images/chevron-right.svg'

class OverheardCard extends React.Component {
  static propTypes = {
    stats: PropTypes.array.isRequired,
  }

  constructor() {
    super()
    this.state = {
      cardNumber: 0,
      show: false,
    }
  }

  nextCard = () => {
    mixpanel.track('By the numbers card change')
    this.setState({
      cardNumber: Math.min(
        this.state.cardNumber + 1,
        this.props.stats.length - 1
      ),
    })
  }

  prevCard = () => {
    mixpanel.track('By the numbers card change')
    this.setState({
      cardNumber: Math.max(this.state.cardNumber - 1, 0),
    })
  }

  setActiveCard = index => {
    mixpanel.track('By the numbers card change')
    this.setState({
      cardNumber: Math.min(index),
    })
  }

  render() {
    const { stats } = this.props
    const activeText = stats[this.state.cardNumber].text
    const activeNumber = stats[this.state.cardNumber].number
    const subtextClass =
      activeText.length < 15
        ? styles.subtextLarge
        : activeText.length < 30
          ? styles.subtextMedium
          : styles.subtextSmall
    return (
      <CardLayout>
        <CardTitle cardTitle="Dartmouth by the Numbers" />
        <div className={styles.numberBody}>
          <img
            src={ChevronLeft}
            className={styles.arrowLeft}
            alt="left"
            onClick={this.prevCard}
          />
          <div className={styles.slide}>
            <span className={styles.numberText}>{activeNumber}</span>
            <span className={subtextClass}>{activeText}</span>
          </div>
          <img
            src={ChevronRight}
            className={styles.arrowRight}
            alt="right"
            onClick={this.nextCard}
          />
        </div>
        <div className="dotstyle dotstyle-fillup">
          <ul>
            {stats.map((s, index) => (
              <li
                className={index === this.state.cardNumber ? 'current' : ''}
                key={index}
                onClick={() => this.setActiveCard(index)}
              >
                <a>A</a>
              </li>
            ))}
          </ul>
        </div>
      </CardLayout>
    )
  }
}

export default OverheardCard
