import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import mixpanel from 'mixpanel-browser'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Layout from '../components/layout'
import Rankings from '../components/rankings'
import Ratings from '../components/ratings'
import Mood from '../components/mood'
import DownloadCard from '../components/download'
import StatsCard from '../components/byTheNumbers'
import OverheardCard from '../components/overheard'

mixpanel.init('2895c5c167e39c37313af0a7c14d0d44')

const IndexPage = ({ data }) => (
  <Layout>
    <div style={{ display: 'flex' }}>
      <div style={{ flex: 3, marginRight: 45 }}>
        <Rankings categories={data.allLeaderboardData.edges} />
        <Ratings categories={data.allApprovalData.edges} />
      </div>
      <div style={{ flex: 2 }}>
        <Mood />
        <StatsCard stats={data.allNumericData.edges.map(({ node }) => node)} />
        <DownloadCard count={data.userCount.count} />
        <OverheardCard
          question="Describe your sex life in 5 words or less."
          answer="No Sex, Just Dank Memes."
        />
      </div>
    </div>
  </Layout>
)

IndexPage.propTypes = {
  data: PropTypes.object,
}

export default IndexPage

export const query = graphql`
  query {
    allLeaderboardData {
      edges {
        node {
          title
          values {
            name
            percent
            number
          }
          _id
        }
      }
    }
    allApprovalData {
      edges {
        node {
          _id
          title
          data {
            date
            value
          }
        }
      }
    }
    allNumericData {
      edges {
        node {
          text
          number
        }
      }
    }
    userCount {
      count
    }
  }
`
