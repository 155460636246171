import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './card.module.css'

const CardLayout = ({ greenCard, children }) => (
  <div className={classnames(styles.cardContainer, { [styles.greenCard]: greenCard })}>
    {children}
  </div>
)

CardLayout.propTypes = {
  greenCard: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

CardLayout.defaultProps = {
  greenCard: false,
}

export default CardLayout
