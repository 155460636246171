import React from 'react'
import PropTypes from 'prop-types'

import CardLayout from './cardLayout'
import CardTitle from './cardTitle'

import styles from './overheard.module.css'

class OverheardCard extends React.Component {
  static propTypes = {
    question: PropTypes.string.isRequired,
    answer: PropTypes.string.isRequired,
  }

  render() {
    const { question, answer } = this.props
    return (
      <CardLayout>
        <CardTitle cardTitle="Overheards" />
        <div className={styles.questionContainer}>
          <div className={styles.question}>
            <span className={styles.label}>Q. </span>
            <span>{question}</span>  
          </div>
          <div className={styles.answer}>
            <span className={styles.label}>A. </span>
            <span>{answer}</span>
          </div>
        </div>
      </CardLayout>
    )
  }
}

export default OverheardCard
