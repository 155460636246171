import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Select, { components } from 'react-select'
import mixpanel from 'mixpanel-browser'
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts'

import CardLayout from './cardLayout'
import CardTitle from './cardTitle'

import styles from './ratings.module.css'

import ArrowImage from '../images/right-arrow.svg'

const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <img
          className={styles.dropdownIndicator}
          src={ArrowImage}
          alt="dropdown indicator"
        />
      </components.DropdownIndicator>
    )
  )
}

class RatingsCard extends React.Component {
  static propTypes = {
    categories: PropTypes.array.isRequired,
  }
  constructor(props) {
    super(props)
    const firstNode = (props.categories[0] || {}).node
    this.state = {
      selectedOption: firstNode
        ? { value: firstNode._id, label: firstNode.title }
        : null,
    }
  }

  changeActiveCategory = selectedOption => {
    mixpanel.track('Rating Category Selected', {
      category: selectedOption.value,
    })
    this.setState({ selectedOption })
  }

  render() {
    const options = this.props.categories.map(({ node }) => ({
      value: node._id,
      label: node.title,
    }))
    const { node = {} } =
      _.find(
        this.props.categories,
        category =>
          category.node._id === (this.state.selectedOption || {}).value
      ) || {}
    const values = node.data || []
    const mainPercent = (
      ((values[values.length - 1] || {}).value || 0) * 100
    ).toFixed(2)
    return (
      <CardLayout>
        <CardTitle cardTitle="Campus Approval Rate" />
        <div className={styles.header}>
          <Select
            value={this.state.selectedOption}
            placeholder="Select a Category..."
            options={options}
            components={{
              DropdownIndicator,
              ClearIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            isSearchable={false}
            onChange={this.changeActiveCategory}
            styles={{
              container: base => ({ ...base, flex: 1 }),
              control: base => ({
                ...base,
                backgroundColor: '#cce1d8',
                cursor: 'pointer',
              }),
            }}
          />
          <div className={styles.percentage}>{mainPercent}%</div>
        </div>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            width={600}
            height={300}
            data={values}
            margin={{ top: 10, right: 45, left: 0, bottom: 10 }}
          >
            <XAxis
              type="category"
              dataKey="date"
              interval={0}
              minTickGap={0}
              tick={{ fontSize: 14, fill: '#9b9b9b' }}
              tickMargin={5}
            />
            <YAxis
              type="number"
              domain={[0, values.length > 0 ? 'dataMax' : 1]}
              unit="%"
              tick={{ fontSize: 14, fill: '#9b9b9b' }}
              tickCount={3}
              tickFormatter={val => _.round(val * 100, 2)}
            />
            <Tooltip formatter={val => `${_.round(val * 100, 2)}%`} />
            <Line
              type="linear"
              dataKey="value"
              stroke="#00693e"
              strokeWidth={2}
              dot={{ r: 5, fill: '#00693e' }}
              activeDot={{ r: 10 }}
              animationDuration={100}
            />
          </LineChart>
        </ResponsiveContainer>
      </CardLayout>
    )
  }
}

export default RatingsCard
