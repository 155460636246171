import React from 'react'
import PropTypes from 'prop-types'
import mixpanel from 'mixpanel-browser'
import _ from 'lodash'

import CardLayout from './cardLayout'
import CardTitle from './cardTitle'

import styles from './rankings.module.css'

import RightArrowImage from '../images/right-arrow.svg'

const PAGE_SIZE = 5

class RankingsCard extends React.Component {
  static propTypes = {
    categories: PropTypes.array.isRequired,
  }
  constructor() {
    super()
    this.state = {
      activeCategory: null,
    }
  }

  makeActive = activeCategory =>
    this.setState({ activeCategory }, () => {
      mixpanel.track('Expand ranking', { ranking: activeCategory })
    })

  removeActive = () => this.setState({ activeCategory: null })

  renderCategories(page) {
    const categories = this.props.categories.slice(
      page * PAGE_SIZE,
      (page + 1) * PAGE_SIZE
    )

    const { activeCategory } = this.state

    while (categories.length < 5) {
      categories.push({ node: { _id: Math.random(), title: null } })
    }

    return categories.map(({ node }) => (
      <div
        key={node._id}
        className={styles.categoryRow}
        onClick={
          this.state.activeCategory && this.state.activeCategory === node._id
            ? () => this.removeActive()
            : () => this.makeActive(node._id)
        }
      >
        <div>
          {!activeCategory || activeCategory === node._id ? node.title : ''}
        </div>
        <img
          className={
            this.state.activeCategory && this.state.activeCategory === node._id
              ? styles.arrowIconRotated
              : styles.arrowIcon
          }
          src={RightArrowImage}
          alt="arrow"
        />
      </div>
    ))
  }

  renderAnswers() {
    const { categories } = this.props
    const { activeCategory } = this.state
    const activeCategoryNode = _.find(
      categories,
      c => c.node._id === activeCategory
    )

    const values =
      !activeCategoryNode || !activeCategoryNode.node
        ? categories.map(category => category.node.values[0] || {})
        : activeCategoryNode.node.values

    while (values.length < 5) {
      values.push({})
    }

    return values.map(({ name, percent }) => (
      <div
        key={name || Math.random()}
        className={styles.answerRow}
        onClick={e => e.stopPropagation()}
      >
        <div>{name}</div>
        <div>
          {activeCategory ? `${((percent || 0) * 100).toFixed(1)}%` : ''}
        </div>
        {activeCategory && (
          <div
            style={{
              width: `${((percent || 0) * 100).toFixed(1)}%`,
              height: '100%',
              position: 'absolute',
              left: 0,
              top: 0,
              opacity: 0.2,
              background: '#005834',
            }}
          />
        )}
      </div>
    ))
  }

  render() {
    console.log(this.props.categories) // eslint-disable-line
    const slidesCount = Math.ceil(this.props.categories.length / PAGE_SIZE)
    return (
      <CardLayout>
        <CardTitle cardTitle="Campus Rankings" subtitle="Click to Expand" />
        {_.range(0, slidesCount).map(page => (
          <div key={`page-${page}`}>
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}>{this.renderCategories(page)}</div>
              <div style={{ flex: 1 }} onClick={this.removeActive}>
                {this.renderAnswers()}
              </div>
            </div>
          </div>
        ))}
      </CardLayout>
    )
  }
}

export default RankingsCard
